import React, { useContext, useState, useEffect } from 'react'
import CustomButton from '../../components/CustomButton'
import { useParams } from 'react-router'
import Input from '../../components/CustomInput'
import {
    Wrapper,
    PtoPLogo,
    ButtonsContainer,
    StyledText,
    StyledError,
    InnerFormContainer,
    MainContainer,
    EstablishmentName,
    DescriptionText,
    PtopLogoContainer,
    Politics,
    CheckContainer,
    StyledTYC,
    StyledCheckbox
} from './style'
import InvoiceService from '../../services/Invoice'
import GeneralContext from '../../context/GeneralContext'
import CurrencyInput from 'react-currency-input-field'
import { Button } from '../../assets'
import { Helmet } from 'react-helmet'
import Public2 from '../../layouts/public2'

const PayWithoutAmount = () => {
    const { hash } = useParams()
    const { displayToast } = useContext(GeneralContext)
    const [isScreenLocked, setIsScreenLocked] = useState(true)
    const [establishment, setEstablishment] = useState()
    const [iva, setIva] = useState()
    const [subtotal, setSubtotal] = useState()
    const [total, setTotal] = useState()
    const [details, setDetails] = useState()
    const [btnDisabled, setBtnDisabled] = useState(true)
    const minAmount = 1
    const maxAmount = 500000
    const [amount, setAmount] = useState()
    const [errorClassName, setErrorAmountClassName] = useState('')
    const [errorAmount, seterrorAmount] = useState('')
    const [errorDetailClassName, setErrorDetailClassName] = useState('')
    const [errorDetail, setErrorDetail] = useState('')
    const [height, setHeight] = useState(window.innerHeight)
    const [acceptTYC, setAcceptTYC] = useState(false)
    const [acceptPP, setAcceptPP] = useState(false)
    const hideLenght = 80
    const [showTyc, setShowTyc] = useState(false)
    const policyURL = 'https://storage-bendo-prod.s3.amazonaws.com/Politicas_de_Privacidad.pdf'

    const handleWindowSizeChange = () => {
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    const getEstablishment = async () => {
        try {
            const response = await InvoiceService.getEstablishmentByHash(hash)
            setEstablishment(response.data.data)
            setIsScreenLocked(false)
        } catch (error) {
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
            setIsScreenLocked(false)
            setEstablishment(error.response?.data?.errors)
        }
    }

    const sendData = async () => {
        setIsScreenLocked(true)
        setBtnDisabled(true)
        try {
            const invoiceBody = {
                subtotal,
                iva,
                total,
                details,
                code: hash
            }

            const response = await InvoiceService.createWithoutAmount(invoiceBody)
            if (response) {
                const url = response?.data?.data?.url
                const invoiceID = response?.data?.data?.id
                const invoiceAPI = response?.data?.data?.invoice_api
                displayToast({
                    type: 'ok',
                    message: 'Prefactura creada por el monto. $' + total
                })
                requestURL(invoiceID, invoiceAPI)
                window.localStorage.setItem('invoiceUrl', url)
                //window.location.replace(url)
            }
        } catch (error) {
            setBtnDisabled(false)
            displayToast({
                type: 'fail',
                message: 'Ocurrió un error creando la prefactura.'
            })
        }
        setIsScreenLocked(false)
    }

    const notificationURL = async (data, type) => {
        setIsScreenLocked(true)
        try {
            await InvoiceService.NotificationUrl(data, type)
            setIsScreenLocked(false)
        } catch (error) {
            setIsScreenLocked(false)
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
        }
    }

    const addListenerPtoP = (invoiceAPI) => {
        try {
            window.P.on('response', async (o) => {
                try {
                    if (o.status.status) {
                        const invoiceTypeI = [0, 1]
                        const invoiceTypeII = [2, 3, 4]

                        const data = {
                            status: {
                                status: o.status.status,
                                message: o.status.message,
                                reason: o.status.reason,
                                date: o.status.date
                            },
                            requestId: o.requestId,
                            reference: o.reference,
                            signature: o.signature
                        }

                        if (invoiceTypeI.includes(invoiceAPI)) {
                            //run main notification
                            notificationURL(data, 'main')
                        } else if (invoiceTypeII.includes(invoiceAPI)) {
                            //run secondary notification for app
                            notificationURL(data, 'minify')
                        }

                        setTimeout(() => {
                            window.location.replace(
                                `/payment/status?paymentStatus=${o.status.status}`
                            )
                        }, 2000)
                    }
                } catch (error) {
                    setIsScreenLocked(false)
                    displayToast({
                        type: 'fail',
                        message: error.response?.data?.message
                    })
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const requestURL = async (invoiceID, invoiceAPI) => {
        setIsScreenLocked(true)
        try {
            const response = await InvoiceService.CreateURLPayment(invoiceID)
            if (response) {
                const requestID = response.data.data.requestID
                const ptopUrl = response.data?.data?.processUrl
                window.localStorage.setItem('requestID', requestID)
                //window.P.init(ptopUrl, { opacity: 0.4 })
                window.location.replace(ptopUrl)
                addListenerPtoP(invoiceAPI)
            }
            setIsScreenLocked(false)
        } catch (error) {
            setIsScreenLocked(false)
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
        }
    }

    useEffect(() => {
        getEstablishment()
    }, [])

    const validateAmount = (value) => {
        const chargeIva = establishment?.charge_iva
        const ivaPercentage = Number(process.env.REACT_APP_IVA ?? 12)
        const amount = value ? Number(value) : null
        if (amount != null && (amount < minAmount || amount > maxAmount)) {
            setErrorAmountClassName('is-invalid')
            seterrorAmount(
                amount < 1
                    ? '* Ingresa un monto mínimo de $1.00'
                    : '* Ingresa un monto igual o menor a $500000.00'
            )
            setBtnDisabled(true)
        }
        if (amount >= minAmount && amount <= maxAmount) {
            if (chargeIva == '1') {
                const subtotal = amount / 1.12
                const iva = amount - subtotal
                setIva(iva)
                setSubtotal(subtotal)
            } else {
                setIva(0)
                setSubtotal(amount)
            }
            setErrorAmountClassName('')
            seterrorAmount('')
        }
        setTotal(amount)
    }

    const validateDetail = (value) => {
        if (value) {
            setErrorDetailClassName('')
            setErrorDetail('')
        } else {
            setErrorDetailClassName('is-invalid')
            setErrorDetail('* Indica el detalle de tu compra')
            setBtnDisabled(true)
        }
    }

    useEffect(() => {
        if (
            amount != '' &&
            amount != null &&
            amount >= 1 &&
            details != '' &&
            details != null &&
            acceptTYC &&
            acceptPP
        ) {
            setBtnDisabled(false)
        } else {
            setBtnDisabled(true)
        }
    }, [amount, details, acceptTYC, acceptPP])

    const tyc_string = establishment?.tyc ?? ''
    const tyc_hide = tyc_string.substring(0, hideLenght)

    return (
        <Public2 subTitle={'Pagos'}>
            <div className="row">
                <Helmet>
                    <script src={process.env.REACT_APP_P2P_URL} async={true} defer={true}></script>
                </Helmet>
                <MainContainer className="col-12 col-md-8 col-xl-6 col-xs-6 offset-md-2 offset-xl-3 offset-xs-3">
                    <EstablishmentName>{establishment?.name}</EstablishmentName>
                    <DescriptionText>Completa tus datos y realiza tu pago</DescriptionText>
                    <Wrapper>
                        <div className="row">
                            <InnerFormContainer>
                                <div className="col-md-10 offset-md-1 col-xl-10 offset-xl-1">
                                    <StyledText>Monto total*</StyledText>
                                    <CurrencyInput
                                        intlConfig={{ locale: 'es-ES', currency: 'USD' }}
                                        placeholder="Ingresa el monto total a pagar"
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        prefix={'$'}
                                        decimalSeparator={'.'}
                                        maxLength={10}
                                        onValueChange={(text) => {
                                            const amount = text == null ? '0' : text
                                            setAmount(amount)
                                            validateAmount(amount)
                                        }}
                                        onKeyPress={(e) => {
                                            const { key, target } = e
                                            const { selectionStart, value } = target
                                            if (key === ',') {
                                                target.value = `${value.substr(
                                                    0,
                                                    selectionStart
                                                )}.${value.substr(selectionStart, value.length)}`
                                            }
                                        }}
                                        style={{ height: 57, borderRadius: 15 }}
                                        className={`form-control ${errorClassName}`}
                                    />
                                    {errorAmount && <StyledError>{errorAmount}</StyledError>}
                                    <br></br>
                                </div>
                                <div className="col-md-10 offset-md-1 col-xl-10 offset-xl-1">
                                    <StyledText>Detalle del pago*</StyledText>
                                    <Input
                                        name="details"
                                        data={{}}
                                        onChange={(e) => {
                                            const text = e.target.value
                                            setDetails(text)
                                            validateDetail(text)
                                        }}
                                        type="text"
                                        placeholder="Ingresa el monto total a pagar"
                                        style={{ height: 57, borderRadius: 15 }}
                                        maxLength={34}
                                        className={`form-control ${errorDetailClassName}`}
                                    />
                                    {errorDetail && <StyledError>{errorDetail}</StyledError>}
                                    {establishment?.charge_iva == '1' &&
                                    Number(total) >= minAmount &&
                                    Number(total) <= maxAmount ? (
                                        <div className="col-12" style={{ paddingTop: 20 }}>
                                            <span>
                                                Monto total a cobrar: ${Number(total).toFixed(2)}
                                            </span>
                                            <br></br>
                                            <span>Subtotal: ${Number(subtotal).toFixed(2)}</span>
                                            <br></br>
                                            <span>IVA: ${Number(iva).toFixed(2)}</span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <Politics className="col-12 col-md-10 offset-md-1 col-xl-10 offset-xl-1">
                                    <span>
                                        <span style={{ fontWeight: 700 }}>Políticas de pago: </span>
                                        {establishment?.tyc_url ? (
                                            <></>
                                        ) : establishment?.tyc ? (
                                            <>
                                                <span style={{ fontWeight: 700 }}>
                                                    Términos y condiciones:{' '}
                                                </span>
                                                <span>{showTyc ? tyc_string : tyc_hide}</span>
                                                <a
                                                    onClick={() =>
                                                        setShowTyc(showTyc ? false : true)
                                                    }
                                                    style={{
                                                        color: Button.primary.defaultColor.active
                                                            .color,
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    {' '}
                                                    {tyc_string.length <= hideLenght
                                                        ? ''
                                                        : showTyc
                                                        ? 'Mostrar menos'
                                                        : 'Mostrar más'}
                                                </a>
                                            </>
                                        ) : (
                                            'Sin políticas'
                                        )}
                                    </span>
                                </Politics>
                                <CheckContainer className="col-12 col-md-10 offset-md-1 col-xl-10 offset-xl-1">
                                    <StyledCheckbox>
                                        <input
                                            type="checkbox"
                                            id="acceptTYC"
                                            checked={acceptTYC}
                                            onChange={(e) => {
                                                setAcceptTYC(e.target.checked)
                                            }}
                                        />
                                        <label htmlFor="acceptTYC">
                                            <span>
                                                He leído y acepto los{' '}
                                                {establishment?.tyc_url ? (
                                                    <StyledTYC
                                                        href={establishment?.tyc_url ?? '#'}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {' '}
                                                        Términos y condiciones.
                                                    </StyledTYC>
                                                ) : (
                                                    'Términos y condiciones.'
                                                )}
                                            </span>
                                        </label>
                                    </StyledCheckbox>
                                    <StyledCheckbox>
                                        <input
                                            type="checkbox"
                                            id="acceptPP"
                                            checked={acceptPP}
                                            onChange={(e) => {
                                                setAcceptPP(e.target.checked)
                                            }}
                                        />
                                        <label htmlFor="acceptPP">
                                            <span>
                                                He leído y acepto el tratamiento de mis datos
                                                personales de acuerdo con las{' '}
                                                <StyledTYC
                                                    href={policyURL}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {' '}
                                                    Políticas de Privacidad.
                                                </StyledTYC>
                                            </span>
                                        </label>
                                    </StyledCheckbox>
                                </CheckContainer>
                                <div className="row">
                                    <ButtonsContainer className="col-8 offset-2 col-md-6 offset-md-3 col-xl-6 offset-xl-3">
                                        <CustomButton
                                            style={{ borderRadius: 27.79 }}
                                            locked={isScreenLocked}
                                            type="submit"
                                            buttonType="primary"
                                            size="lg"
                                            disabled={btnDisabled}
                                            onClick={sendData}
                                            disableBackground="#D3D3D3"
                                            disableColor="#ffffff"
                                        >
                                            Realizar pago
                                        </CustomButton>
                                    </ButtonsContainer>
                                </div>
                            </InnerFormContainer>
                        </div>
                        <div className="row">
                            <PtopLogoContainer className="col-10 col-md-6 col-xl-6 col-xs-6 offset-1 offset-md-3 offset-xl-3 offset-xs-3">
                                <h6 style={{ fontSize: 10, fontWeight: 200, marginBottom: 0 }}>
                                    Tu pago seguro con:
                                </h6>
                                <PtoPLogo
                                    style={{ marginTop: 0 }}
                                    src="https://static.placetopay.com/placetopay-logo.svg"
                                    alt="place to pay"
                                />
                            </PtopLogoContainer>
                        </div>
                    </Wrapper>
                </MainContainer>
            </div>
        </Public2>
    )
}

export default PayWithoutAmount
